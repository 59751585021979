


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Concentrations from 'common-modules/src/store/Concentrations';
import BackBar from '@/components/BackBar.vue';
import jwlWbt from '@/components/jwlWbt.vue';
import Wbt from '@/store/Wbt';
import { RunUnit } from '@/store/interface/Unit';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

const HIDE_MENU_CLASS = 'jwl-body--hide-menu';

@Component({
  components: {
    BackBar,
    CommonError,
    jwlWbt,
  },
  computed: mapGetters([
    'baseConcentrations',
  ]),
})
export default class JwlWbt extends Vue {
  baseConcentrations!: Concentrations;

  loaded = false;
  wbt: Wbt | null = null;
  error = null;

  @Watch('$route')
  fetchData (): void {
    this.loaded = false;
    this.$store.dispatch('getData', `wbt/${this.$route.params.unit}`)
      .then((data) => {
        this.wbt = new Wbt(data);
        this.loaded = true;
      })
      .catch((e) => {
        this.error = e;
      });
  }

  get unit (): RunUnit | null {
    const concentrations = this.baseConcentrations.dataAsFlatArray();
    const unitCode = this.$route.params.unit;
    let unit = null;
    concentrations.forEach((concentration) => {
      concentration.courses.forEach((runCourse) => {
        runCourse.units.forEach((runUnit) => {
          if (runUnit.code === unitCode) {
            unit = runUnit;
          }
        });
      });
    });
    return unit;
  }

  mounted (): void {
    this.fetchData();
    document.body.classList.add(HIDE_MENU_CLASS);
  }

  beforeDestroy (): void {
    document.body.classList.remove(HIDE_MENU_CLASS);
  }
}
